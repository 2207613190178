<template>
    <div>
        <h2 class="border-bottom pb-2 mb-3"><i class="fa fa-bullseye"></i> Queue Report</h2>
        <div class="row listing bg-white card p-3 m-1">
            <div class="col-12">
                <div class="row">
                    <div class="col-12">
                        <div class="form-inline ml-3">
                            <div class="form-group mr-1">
                                <select v-model="filter.priority" class="form-control border-dark">
                                    <option value="" selected>-- Any Priority --</option>
                                    <option value="normal">Normal</option>
                                    <option value="highest">Highest</option>
                                </select>
                            </div>
                            <div class="form-group mr-1">
                                <select v-model="filter.status" class="form-control border-dark">
                                    <option value="" selected>-- Any Status --</option>
                                    <option value="active">Active</option>
                                    <option value="proxy">Proxy</option>
                                    <option value="pending">Pending</option>
                                    <option value="done">Done</option>
                                </select>
                            </div>
                            <div class="form-group mr-1">
                                <select @change="refresh" v-model="refreshData" class="form-control border-dark">
                                    <option value="off" selected>-- Refresh Off --</option>
                                    <option value="5000">5 Sec</option>
                                    <option value="8000">8 Sec</option>
                                    <option value="15000">15 Sec</option>
                                    <option value="60000">1 Min</option>
                                </select>
                            </div>
                            <div class="form-group mr-1">
                                <select v-model="filter.client" class="form-control border-dark">
                                    <option value="" selected>-- Any Client --</option>
                                    <option v-for="client in dropdownClients" :key="client._id" :value="client._id">{{client.name}}</option>
                                </select>
                            </div>
                            <div class="form-group mr-1">
                                <select v-model="filter.product" class="form-control border-dark">
                                    <option value="" selected>-- Any Product--</option>
                                    <option v-for="product in products" :key="product._id" :value="product._id">{{product.name}}</option>
                                </select>
                            </div>
                            <button type="button" class="btn text-white mr-1" style="background-color: #383838" @click="load">
                                <i class="fa fa-search" title="Search" v-if="!sLoading"></i>
<!--                                <span-->
<!--                                    class="spinner-border spinner-border-sm"-->
<!--                                    role="status"-->
<!--                                    aria-hidden="true"-->
<!--                                    v-if="sLoading"-->
<!--                                ></span>-->
                                <span>Search Queue</span></button>
                            <button type="button" class="btn btn-primary mr-2" @click="Reload">Refresh</button>
                        </div>

                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-12">
                        <div class="form-inline d-flex justify-content-center align-content-center">
                            <div class="col-3">
                                <div class="card border-dark">
                                    <div class="card-body text-center">
                                        <h4>{{stat.active||0}} Active</h4>
                                        <h5></h5>
                                    </div>
                                </div>
                            </div>

                            <div class="col-3">
                                <div class="card border-dark">
                                    <div class="card-body text-center">
                                        <h4>{{stat.pending||0}} Pending</h4>
                                        <h5></h5>
                                    </div>
                                </div>
                            </div>

                            <div class="col-3">
                                <div class="card border-dark">
                                    <div class="card-body text-center">
                                        <h4>{{stat.proxy||0}} Proxy</h4>
                                        <h5></h5>
                                    </div>
                                </div>
                            </div>

                            <div class="col-3 float-right">
                                <div class="card border-dark">
                                    <div class="card-body text-center">
                                        <h4>{{stat.done||0}} Done</h4>
                                        <h5></h5>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div class="listing pt-3 mt-3" style="background-color: white; padding:2%;">
                    <table class="table table-hover table-bordered table-striped ">
                        <thead>
                        <tr>
                            <td>Priority</td>
                            <td>Campaign Name</td>
                            <td>Threshold</td>
                            <td>Client</td>
                            <td>Country</td>
                            <td>State</td>
                            <td>City</td>
                            <td>Status</td>
                            <td>Date</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="data in items.data" v-bind:key="data._id">
                            <td>{{data.priority}}</td>
                            <td>{{(data.campaign) ? data.campaign.name : 'N/A'}}</td>
                            <td>{{(data.threshold) ? data.threshold : 'N/A'}}</td>
                            <td>{{(data.campaign) ? data.campaign.client : 'N/A'}}</td>
                            <td>{{(data.campaign && data.campaign.geo) ? data.campaign.geo.country : 'N/A'}}</td>
                            <td>{{(data.campaign && data.campaign.geo) ? data.campaign.geo.state : 'N/A'}}</td>
                            <td>{{(data.campaign && data.campaign.geo) ? data.campaign.geo.city : 'N/A'}}</td>
                            <td>{{data.status}}</td>
                            <td>{{ data.date|userTime(user.timezone) }}</td>
                        </tr>
                        </tbody>
                    </table>

                    <hr/>

                    <div class="row">
                        <div class="col-12 col-sm-9">
                            <v-pagination
                                v-model="page"
                                :pageCount="Math.ceil(items.total/max)"
                                :classes="bootstrapPaginationClasses"
                                :labels="customLabels"
                                @input="load"></v-pagination>
                        </div>
                        <div class="col-12 col-sm-3 text-right">
                            <span>{{items.data.length * Number(page|1)}}</span> of <span>{{items.total}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios";
    import vPagination from "vue-plain-pagination";
    import swal from "sweetalert";

    export default {
        name: "Queue.Report",
        props: ['user'],
        components: {
            vPagination
        },
        data: function () {
            return {
                action:'',
                campaign:null,
                editor: false,
                item: {},
                refreshData:'off',
                options: [
                    'foo',
                    'bar',
                    'baz'
                ],
                dropdownClients: [],
                items:{total:0,data:[]},
                filter:{status:"active",priority:'',client: '', product: '',},
                clients: [],
                products: [],
                report:{today:'',},
                providers:[],
                stat:{},
                interval:'',
                states:[],
                max: 25,
                page: 1,
                status:{active:'active', pending:'pending',done:'done',proxy:'proxy'},
                cities:[],
                preloader: false,
                sLoading: false,
                bootstrapPaginationClasses: {
                    ul: 'pagination',
                    li: 'page-item',
                    liActive: 'active',
                    liDisable: 'disabled',
                    button: 'page-link'
                },
                customLabels: {
                    first: 'First',
                    prev: 'Previous',
                    next: 'Next',
                    last: 'Last'
                },
                searching: false
            }
        },
        created: function(){
            // this.load();
            this.Active();
            this.Pending();
            this.Done();
            this.Proxy();
            this.loadClients();
            this.loadProducts();
            this.GetClientsList();
            this.$root.preloader = false
            window.addEventListener("keydown",this.windowListener);

        },
        beforeDestroy() {
            clearInterval(this.interval);
        },
        destroyed() {
            clearInterval(this.interval);
            window.removeEventListener("keydown",this.windowListener);
        },
        methods:{
            windowListener: function(e){
                if(e.keyCode === 13 && !this.searching){
                    this.search();
                }
            },
            refresh: function(){
                if(this.refreshData && this.refreshData !=='off') {
                    clearInterval(this.interval);
                    this.interval = setInterval(this.Calls, this.refreshData);
                } else {
                    clearInterval(this.interval);
                }
            },

            myChangeEvent(val){
                console.log(val);
            },
            mySelectEvent({id, text}){
                console.log({id, text})
            },

            Calls: function(){
                if(this.refreshData && this.refreshData !=='off'){
                    this.Active();
                    this.Pending();
                    this.Done();
                    this.Proxy();
                    this.load();
                }
            },

            Reload: function(){
                this.Active();
                this.Pending();
                this.Done();
                this.Proxy();
                this.load();
        },
        loadClients: function(){
            //Load the clients
            var request = {'apikey': this.$root.apikey};
            //this.$root.preloader = true;

            return axios.get(`${this.$root.serverUrl}/admin/campaigns/clients`, {params:request}).then(function(resp){
                //Store the stats
                this.clients = (resp.data && resp.data.data)? resp.data.data : [];
                //this.$root.preloader = false;
            }.bind(this));
        },
            loadProducts: function(){
                //Load the clients
                var request = {'apikey': this.$root.apikey};
                //this.$root.preloader = true;

                return axios.get(`${this.$root.serverUrl}/admin/products/dropdown-list`, {params:request}).then(function(resp){
                    //Store the stats
                    this.products = (resp.data && resp.data.data)? resp.data.data : [];
                    //this.$root.preloader = false;
                }.bind(this));
            },
            GetClientsList: function(){
                this.cities = [];
                var request = {'apikey': this.$root.apikey};

                return axios.get(`${this.$root.serverUrl}/admin/clients/dropdown-list`, {params:request}).then(function(resp){
                    //Store the stats
                    this.dropdownClients = (resp.data)? resp.data.data : [];
                }.bind(this));
            },
            load: function(){
                //Load the campaign
                var page
                if(event && event.type == "click" && event.currentTarget && event.currentTarget.className.indexOf(this.bootstrapPaginationClasses.button) > -1){
                    //Pagination triggered event
                    console.log(`Pagination event: ${this.page}: ${event.currentTarget.className}`)
                    page = (this.page)? ((this.page == 1)? 0 : Number(this.page-1) ) : 0
                } else
                    page = 0;

                var request = {'apikey': this.$root.apikey,filters:{}};
                request.page = page
                request.max = this.max
                if(this.filter.status) request.filters.status = this.filter.status;
                if(this.filter.priority) request.filters.priority = this.filter.priority;
                if(this.filter.client) request.filters.client_id = this.filter.client;
                if(this.filter.product) request.filters.product_id = this.filter.product;

                this.sLoading = true;
                clearInterval(this.interval);

                return axios.get(`${this.$root.serverUrl}/admin/queue`, {params:request}).then(function(resp){
                    this.items = []
                    this.items = (resp.data && resp.data)? resp.data : null;
                    this.sLoading = false;
                    this.refresh();
                }.bind(this));
            },

            Campaign: function(search, loading){
                loading = true;
                console.log(search, loading);
                var page
                if(event && event.type == "click" && event.currentTarget && event.currentTarget.className.indexOf(this.bootstrapPaginationClasses.button) > -1){
                    //Pagination triggered event
                    console.log(`Pagination event: ${this.page}: ${event.currentTarget.className}`)
                    page = (this.page)? ((this.page == 1)? 0 : Number(this.page-1) ) : 0
                } else
                    page = 0;

                var request = {'apikey': this.$root.apikey, sSearch:null, filters:{},geo:{}}
                if(search) request.sSearch = search;
                request.page = page
                request.max = this.max

                //this.$root.preloader = true

                axios.get(`${this.$root.serverUrl}/admin/campaigns`, {params:request}).then(function(resp){
                    //Store the stats
                    this.options = (resp.data)? resp.data.data : {total:0, data:[]}
                    //this.$root.preloader = false
                }.bind(this)).catch(function(err){
                    this.$root.preloader = false;
                    swal({title:'Oops', text: err.message, icon: 'error'})
                })
            },


            search: function(){
                this.searching = true;
                //Load the items
                var page
                if(event && event.type == "click" && event.currentTarget && event.currentTarget.className.indexOf(this.bootstrapPaginationClasses.button) > -1){
                    //Pagination triggered event
                    console.log(`Pagination event: ${this.page}: ${event.currentTarget.className}`)
                    page = (this.page)? ((this.page == 1)? 0 : Number(this.page-1) ) : 0
                } else
                    page = 0;

                var request = {'apikey': this.$root.apikey, sSearch:null, filters:{},geo:{}}
                if(this.filter.search) request.sSearch = this.filter.search;
                if(this.filter.type) request.filters.type = this.filter.type;
                if(this.filter.client) request.filters.client = this.filter.client;
                if(this.filter.proxy) request.filters.proxy = this.filter.proxy;
                if(this.filter.status) request.filters.status = this.filter.status;
                if(this.filter.method) request.filters.method = this.filter.method;
                if(this.filter.country) request.geo.country = this.filter.country;
                if(this.filter.state) request.geo.state = this.filter.state;
                if(this.filter.city) request.geo.city = this.filter.city;
                request.page = page
                request.max = this.max

                this.$root.preloader = true

                axios.get(`${this.$root.serverUrl}/admin/reports/queue`, {params:request}).then(function(resp){
                    //Store the stats
                    this.items = []
                    this.items = (resp.data)? resp.data : {total:0, data:[]}
                    this.$root.preloader = false
                    this.searching = false;

                }.bind(this)).catch(function(err){
                    this.$root.preloader = false;
                    swal({title:'Oops', text: err.message, icon: 'error'})
                    this.searching = false;
                })
            },

            Active: function(){
                //Load the campaign
                var request = {'apikey': this.$root.apikey,filters:{}};

                return axios.get(`${this.$root.serverUrl}/admin/queue/count/${this.status.active}`, {params:request}).then(function(resp){
                    this.stat.active = (resp.data && resp.data)? resp.data.data : null;

                }.bind(this));
            },

            Pending: function(){
                //Load the campaign
                var request = {'apikey': this.$root.apikey,filters:{}};

                return axios.get(`${this.$root.serverUrl}/admin/queue/count/${this.status.pending}`, {params:request}).then(function(resp){
                    this.stat.pending = (resp.data && resp.data)? resp.data.data : null;
                }.bind(this));
            },
            Done: function(){
                //Load the campaign
                var request = {'apikey': this.$root.apikey,filters:{}};

                return axios.get(`${this.$root.serverUrl}/admin/queue/count/${this.status.done}`, {params:request}).then(function(resp){
                    this.stat.done = (resp.data && resp.data)? resp.data.data : null;
                }.bind(this));
            },
            Proxy: function(){
                //Load the campaign
                var request = {'apikey': this.$root.apikey,filters:{}};

                return axios.get(`${this.$root.serverUrl}/admin/queue/count/${this.status.proxy}`, {params:request}).then(function(resp){
                    this.stat.proxy = (resp.data && resp.data)? resp.data.data : null;
                }.bind(this));
            },
        }
    }
</script>

<style scoped>
button span {
    max-width: 0;
    -webkit-transition: max-width 1s;
    -moz-transition: max-width 1s;
    -o-transition: max-width 1s;
    transition: max-width 1s;
    display: inline-block;
    vertical-align: top;
    white-space: nowrap;
    overflow: hidden;
}
button:hover span {
    max-width: 9rem;
}
</style>
